.app {
  display: flex;
  flex-direction: column;
  background-color: #d2fa94;
  min-height: 100vh;
  font-family: DMSans-Regular, sans-serif;
}

.app-header {
  background-color: #010064;
  height: 94px;
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 400;
  font-style: normal;
  padding-bottom: 10px;
}

.app-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #010064;
}

.app-footer {
  position: relative;
  bottom: 0;
  margin-top: auto;
  min-height: 20vh;
  color: white;
}

@font-face {
  font-family: "DMSans-Regular";
  src: local("DMSans-Regular"), url("../public/DMSans-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

.bg-deep-block-blue {
  background-color: #010064;
}

.bg-deep-block-green {
  background-color: #d2fa94;
}

.bg-deep-block-grey {
  background-color: #9999c0;
}

.text-deep-block-blue {
  color: #010064;
}

.text-deep-block-green {
  color: #d2fa94;
}

.text-deep-block-grey {
  color: #9999c0;
}