
.actualite-container {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.actualite-intro {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: 10px 40px 40px 100px;
    background-color: #010064;
    color: #fff;
}

.actualite-intro-left {
    grid-column: 1 / 8;
}

.actualite-intro-title {
    font-size: 60px;
    margin: 0;
    padding-bottom: 30px;
}

.actualite-intro-description {
    font-size: 20px;
}

.jaune {
    color: #d2fa94;
}

.actualite-main {
    background-color: #e4e3d8;
}

/* Ajustement des tailles des images et vidéos */
.article-img,
.article-video,
.img-slide {
    max-width: 600px;
    height: auto;
    object-fit: cover;
}

.youtube-video {
    width: 600px;
    height: 340px;
    border: none;
}

.article-Titre {
    margin-top: 0;
    font-size: 20px;
    color: #303030;
}

.article-Description {
    font-size: 18px;
    color: #303030;
}

/* Ajustement pour aligner le texte à droite des médias */
.article-Text {
    margin-left: 20px;
    flex: 1; /* S'assure que le texte occupe l'espace restant */
}

#articles {
    display: flex;
    flex-direction: column;
    margin: 0 100px;
    padding-top: 50px;
}

/* Conteneur de lien de l'article, on garde le flex-direction en row */
.a-article {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    text-decoration: none;
    align-items: flex-start; /* S'assure que les éléments sont alignés en haut */
}

/* Slider container styles */
.slider-container {
    position: relative;
    width: 600px; /* Limite la largeur du slider à la même taille que les images */
    height: 400px;
    overflow: hidden;
}

.img-slide {
    display: none; /* Masquer les images par défaut */
    width: 100%; /* Chaque image occupe toute la largeur du slider */
    height: 100%; /* Ajustement pour que les images remplissent le slider */
    object-fit: cover; /* Assure que les images conservent leurs proportions */
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    background-size: contain;
    pointer-events: none;
    opacity: 0.7;
}

/* Boutons du slider */
.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

button {
    background-color: #010064;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.slider-container {
    position: relative; /* Nécessaire pour le positionnement absolu des boutons */
}

.slider-button {
    position: absolute;
    top: 50%; /* Centre verticalement */
    transform: translateY(-50%); /* Ajustement pour centrer */
    background-color: rgba(0, 0, 0, 0.7); /* Fond semi-transparent */
    border: none;
    cursor: pointer;
    padding: 100% 10px; /* Ajoute du padding pour rendre le bouton plus cliquable */
}

.slider-button:first-child {
    left: 0; /* Positionnement à gauche */
}

.slider-button:last-child {
    right: 0; /* Positionnement à droite */
}

.slider-arrow {
    width: 30px; /* Ajuste la taille selon tes besoins */
    height: auto;
}

.no-cursor {
    cursor: default; /* Change le curseur à une flèche par défaut */
}

#mobile-articles {
    display: none;
}

.mobile-article-text {
    display: none;
}

@media (max-width: 1270px) {
    .actualite-intro {
        display: flex;
        grid-template-columns: repeat(12, 1fr);
        padding: 20px 40px 20px 20px;
        background-color: #010064;
        color: #fff;
    }

    .actualite-intro-title {
        font-size: 24px;
        padding-bottom: 10px;
    }

    .actualite-intro-description {
        font-size: 16px;
        margin: 0;
    }

    #articles {
        display: flex;
        flex-direction: column;
        margin: 0 5%;
        padding-top: 20px;
    }

    .article {
        margin-bottom: 20px;
    }

    /* Conteneur de lien de l'article, on garde le flex-direction en row */
    .a-article {
        display: flex;
        flex-direction: row;
        margin-bottom: 40px;
        text-decoration: none;
        justify-content: center;
        align-items: flex-start; /* S'assure que les éléments sont alignés en haut */
    }

    /* Ajustement des tailles des images et vidéos */
    .article-img,
    .article-video,
    .img-slide {
        width: 600px;
        min-width: 100px;
        object-fit: contain;
        overflow: hidden;
    }

    .img-slide {
        display: none; /* Masquer les images par défaut */
        width: 100%; /* Chaque image occupe toute la largeur du slider */
        height: 100%; /* Ajustement pour que les images remplissent le slider */
        object-fit: contain; /* Assure que les images conservent leurs proportions */
    }

    .mobile-adapt {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .article-img,
    .article-video {
        object-fit: cover;
    }

    /* Slider container styles */
    .slider-container {
        position: relative;
        width: 600px; /* Limite la largeur du slider à la même taille que les images */
        height: auto;
        overflow: hidden;
    }

    .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80px;
        height: 80px;
        background-size: contain;
        pointer-events: none;
        opacity: 0.7;
    }

    /* Boutons du slider */
    .button-container {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    button {
        background-color: #010064;
        color: white;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
    }

    .slider-button {
        position: absolute;
        top: 50%; /* Centre verticalement */
        transform: translateY(-50%); /* Ajustement pour centrer */
        background-color: rgba(0, 0, 0, 0.7); /* Fond semi-transparent */
        border: none;
        cursor: pointer;
        padding: 100% 10px; /* Ajoute du padding pour rendre le bouton plus cliquable */
    }

    /* Ajustement pour aligner le texte à droite des médias */
    .article-Text {
        display: none;
    }

    .mobile-article-text {
        display: block;
        margin: 0 20px;
    }

    .article-Titre {
        margin-top: 0;
        font-size: 24px;
        text-align: center;
        font-weight: bold;
        color: #303030;
    }

    .article-Description {
        text-align: center;
        font-size: 18px;
        color: #303030;
    }
}