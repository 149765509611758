.header-container {
  display: flex;
  flex: 1;
  width: 100%;
  padding: 10px 0;
  z-index: 10;
}

.header-section {
  display: grid;
  flex: 1;
  padding: 10px;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 20px;
}

.header-logo {
  display: flex;
  flex-direction: column;
  grid-column: 1 / 4;
  align-self: center;
}

.header-logo a {
  align-self: center;
  text-decoration: none;
  color: white;
}

.header-logo img {
  display: block;
  max-width: 100%;
  object-fit: contain;
  width: 160px;
}

.header-navbar {
  display: flex;
  flex-direction: column;
  grid-column: 4 / 11;
  align-self: center;
  margin: 0;
  justify-content: center;
  position: relative;
  min-height: 20px;
}

.header-navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.headbar-navbar-separator {
  box-sizing: border-box;
  display: block;
  width: 100%;
  min-width: 10px;
  max-width: 50px;
  min-height: 20px;
}

.header-navbar a {
  display: flex;
  background-color: transparent;
  border: medium;
  padding: 0;
  cursor: pointer;
  font-size: 20px;
  color: white;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}

.header-navbar a:hover {
  color: #d2fa94;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 10px;
}

.header-meet-button {
  display: flex;
  flex-direction: column;
  grid-column: 11 / 13;
  align-self: center;
  margin: 0 -5px 0 -15px;
  text-align: center;
}

.header-meet-button button {
  text-decoration: none;
  color: #010064;
  background-color: #d2fa94;
  border-radius: 100px;
  font-size: 18px;
  padding: 15px 25px;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.header-meet-button button::after {
  content: ' ';
  position: absolute;
  top: -50%;
  right: -60%;
  bottom: -50%;
  left: -60%;
  background: linear-gradient(50deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%);
  transform: translate(-80%, 0px);
  display: block;
  animation: 5s cfs-glimmer infinite;
}



@keyframes cfs-glimmer {
  0% {
    -webkit-transform: translate(-80%, 0px);
    -ms-transform: translate(-80%, 0px);
    transform: translate(-80%, 0px);
  }

  50% {
    -webkit-transform: translate(80%, 0px);
    -ms-transform: translate(80%, 0px);
    transform: translate(80%, 0px);
  }
}

.popup{
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.calendly{
  border: none;
  border-radius: 15px;
}

.croix{
  margin-bottom: -70px;
  margin-right: -400px;
  padding: 10px;
  cursor: pointer;
  z-index: 100;
  width: 50px;
}

.mobile-header-section {
  display: none;
}

.mobile-navbar-list-open {
  display: none;
}

.mobile-header-navbar {
  display: none;
}

@media (max-width: 1330px) {
  .header-navbar a{
    font-size: 18px;
  }

  .header-meet-button button {
    font-size: 16px;
    padding: 15px 15px;
  }
}

@media (max-width: 1100px) {
  .header-logo img {
    width: 140px;
  }

  .header-navbar a{
    font-size: 16px;
  }

  .header-meet-button button {
    font-size: 12px;
    padding: 15px 15px;
  }
}

@media (max-width: 768px) {
  .header-section {
    display: none;
  }

  .mobile-header-section {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .mobile-navbar-open-button {
    display: flex;
    width: 40px;
    height: 40px;
    z-index: 10;
    cursor: pointer;
  }

  .mobile-navbar-open-button #open div {
    display: block;
    transform-origin: 0 0 0;
    width: 33px;
    height: 4px;
    position: relative;
    border-radius: 3px;
    background-color: white;
    margin: 0 0 5px 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s, background-color 0.3s ease-in 0.15s;
  }

  .header-logo {
    display: flex;
    flex-direction: column;
    margin: 20px 0 0 5px;
  }

  .header-meet-button {
    margin: 5px 0 0 0;
  }

  .header-meet-button button {
    border-radius: 100px;
    font-size: 18px;
    padding: 10px 15px;
  }

  .header-navbar {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  .mobile-header-navbar {
    display: none;
    height: 100vh;
    width: 40%;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #4d4d93;
    padding-top: 50px;
    padding-left: 10px;
    transition: width 0.2s ease-in-out;
  }

  .mobile-header-navbar a {
    color: #e4e3d8;
    text-decoration: none;
    outline: none;
    font-size: 20px;
    margin-bottom: 20px;
  }
}