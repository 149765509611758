.service-de-signature-connexion{
  background-color: #d2fa94;
  font-size: 20px;
  padding: 15px 30px;
  border-radius: 1000px;
  cursor: pointer;
  color: #010064;
  text-decoration: none;
}

b {
  color: #d2fa94;
}

.services-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.services-intro {
  background-color: #010064;
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
}

.services-intro-left {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 70%;
  padding-left: 5%;
  margin-top: 35px;
}

.services-intro-titre {
  color: white;
  font-size: 53px;
  margin-bottom: 40px;
  margin-top: 0px;
}

.services-intro-titre strong {
  color: #D2FA94;
}

.services-intro-titre * {
  margin: 0;
}

.services-intro-description {
  color: white;
  margin-top: 0px;
  font-size: 20px;
  line-height: 40px;
}

.services-intro-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.services-intro-logo {
  width: 50%;
  margin-top: 35px;
}

.services-intro-nom {
  color: white;
  font-size: 20px;
  margin-top: 20px;
}

.jaune {
  color: #d2fa94;
}

.services-depot-capital-mobile {
  background-color: #010064;
  color: #d2fa94;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.services-depot-capital-mobile-img {
  width: 100px;
}

.services-depot-capital-mobile-text{
  font-size: 15px;
  font-weight: 400;
  text-align: center;
}

.services-depot-capital-mobile-bouton{
  background-color: #d2fa94;
  color: #010064;
  font-size: 15px;
  padding: 8px 40px;
  border-radius: 1000px;
  cursor: pointer;
  margin-top: 20px;
  text-decoration: none;
}

.services-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  background-color: #d2fa94;
}

.services-main-titre {
  color: #010064;
  font-size: 58px;
  margin-bottom: 40px;
  margin-top: 0px;
  text-align: center;
  font-weight: 400;
  padding: 0px 50px;
}

.services-main-iframe {
  width: 90%;
  height: 1250px;
  border: none;
  border-radius: 5px;
  margin-bottom: 30px;
}

.services-main-forms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-bottom: 50px;
  background-color: white;
  border-radius: 5px;
  padding: 20px 100px 50px 100px;
  box-sizing: border-box;
}

.services-main-forms-titre {
  color: #010064;
  font-size: 40px;
  margin-bottom: 40px;
  margin-top: 0px;
  text-align: right;
  font-weight: 400;
}

.services-main-champs {
  background-color: white;
  padding: 10px 5px;
  border: none;
  border-bottom: #010064 1px solid;
  width: 100%;
  color: #010064;
  font-size: 18px;
  font-family: DMSans-Regular;
}


.services-main-champs-civilite {
  background-color: white;
  padding: 10px 0px;
  border: none;
  border-bottom: #010064 1px solid;
  width: 100%;
  color: #010064;
  font-size: 18px;
  font-family: DMSans-Regular;
  display: flex;

}

.services-main-submit {
  background-color: #010064;
  color: white;
  font-size: 20px;
  padding: 18px 90px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

#services-main-champs-pays {
  background-color: white;
  padding: 15px 10px;
  border: #010064 1px solid;
  border-radius: 5px;
  width: 100%;
  font-size: 18px;
  color: #010064;
}

.services-main-placement-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

.services-main-champs-placement {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 0px;
  margin-bottom: 30px;
  width: 100%;
}

.services-main-champs::placeholder {
  color: #010064;
  font-size: 18px;
  font-family: DMSans-Regular;
}

.services-main-forms-placement{
  width: 100%;
}

.services-main-forms-paiement{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #bfc6d1;
  border-radius: 5px;
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  margin-bottom: 50px;
}

.services-main-forms-paiement-label{
  font-size: 20px;
  color: #010064;
  margin-left: 10px;
}

.services-main-forms-paiement-img{
  width: 250px;
}

.services-main-formules-taxes,
.services-main-formules-total {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.services-main-formules-simple{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.services-main-formule-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 15px;
}

.services-main-formules-nom {
  font-weight: bold;
  color: black !important;
}

.services-formule-item{
  margin-bottom: 25px;
}

.services-main-formules-nom-taxes{
  font-weight: 400;
  color: black;
}

.services-main-formules-prix {
  font-size: 18px;
}

.services-main-formule-radio {
  margin-right: 10px;
}

.services-main-formules-options {
  width: 50%;
}

.services-main-rnsv-info{
  width: 100%;
  color: black;
}

.services-main-rnsv-lien{
  color: #010064;
  text-decoration: underline;
  font-weight: bold;
}

.services-main-champs-cvc {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.services-main-cvc-gif{
  width: 100px;
  margin-left: -100px;
  margin-bottom: -100px;
  margin-top: -100px;
}

.services-main-cachet-serveur-info{
  color: black;
  font-size: 13px;
  margin-top: 10px;
  width: 50%;
  text-align: right;
  line-height: 20px;
}

.services-intro-cachet-serveur-info{
  background-color: #010064;
  color: white;
  display: flex;
  justify-content: center;
}

.services-intro-cachet-serveur-paragraphe{
  background-color: #4d4d93;
  padding: 10px 50px;
  width: 96%;
  border-radius: 15px;
  box-sizing: border-box;
  margin-bottom: 50px;
}

.services-intro-cachet-serveur-sous-titre{
  font-size: 30px;
  font-weight: 400;
}

.services-intro-cachet-serveur-text{
  font-size: 20px;
}

.services-autres {
  background-color: #e4e3d8;
  padding-top: 1px;
}

.services-autres-left {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 50%;
  padding-left: 100px;
  margin-top: 35px;
  margin-bottom: 50px;
}

.services-autres-titre {
  color: #010064;
  font-size: 53px;
  margin-bottom: 40px;
  margin-top: 0px;
}

.services-autres-description {
  color: #00000080;
  margin-top: 0px;
  font-size: 20px;
}

.services-autres-services {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 50px;
  width: 400px;
  text-decoration: none;
  cursor: pointer;
  scroll-behavior: initial;
}

.services-autres-img {
  width: 100px;
  cursor: pointer;
}

.services-autres-titreService {
  color: #010064;
  font-size: 24px;
  margin: 0;
}

.services-autres-titreService:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
  cursor: pointer;
}

.services-autres-descriptionService {
  color: #010064;
  font-size: 18px;
  margin: 0;
}

.services-autres-texteService {
  margin-left: 10px;
}

.services-autres-grille {
  margin-left: 5%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  column-gap: 10px;
}

@media (max-width: 1270px) {
    .services-autres-services{
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: space-between;
        margin-bottom: 50px;
        width: auto;
        text-decoration: none;
        cursor: pointer;
        scroll-behavior: initial;
    }
    .services-autres-texteService{
        margin-left: 10px;
    }
}


@media (max-width: 768px) {

  .services-intro{
    flex-direction: row;
    padding-bottom: 0px;
  }

  .services-intro-left{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding-left: 20px;
    margin-top: 15px;
  }

  .services-intro-titre{
    color: white;
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 0px;
  }

  .services-intro-description{
    color: white;
    margin-top: 0px;
    font-size: 16px;
  }

  .services-intro-right {
    display: none;
  }

  .services-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    background-color: #d2fa94;
  }

  .services-main-titre{
    color: #010064;
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 0;
    text-align: center;
    font-weight: 400;
    padding: 0 10px;
  }

  .services-main-forms {
    width: 90%;
    margin-bottom: 50px;
    padding: 20px;
  }

  .services-main-forms-titre{
    font-size: 24px;
    margin-bottom: 20px;
  }

  .services-main-formules-options {
    width: 100%;
  }

  .services-main-champs-civilite{
    font-size: 14px;
  }

  .services-main-champs {
    font-size: 14px;
  }

  .services-main-champs::placeholder {
    font-size: 14px;
  }

  #services-main-champs-pays {
    font-size: 12px;
  }

  .services-main-forms-paiement-img{
    width: 150px;
  }

  .services-main-cvc-gif{
    width: 50px;
    margin-left: -100px;
  }

  .services-main-submit{
    width: 100%;
  }

  .services-autres-left{
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .services-autres-titre{
    font-size: 24px;
    margin-bottom: 40px;
    margin-top: 0;
  }

  .services-autres-description{
    margin-top: 0;
    font-size: 20px;
  }

  .services-autres-grille {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, minmax(70px, 1fr));
    grid-template-rows: auto;
    column-gap: 1;
    row-gap: 0;
    margin-left: 0;
  }

  .services-autres-services{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: initial;
    margin-bottom: 20px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    padding: 0px 10px;
  }

  .services-autres-titreService{
    font-size: 20px;
  }

  .services-autres-texteService{
    margin-left: 0;
  }

  .services-autres-descriptionService{
    font-size: 16px;
  }
}