.contact-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    background-color: #010064;
    color: white;
    padding: 50px 100px;
    box-sizing: border-box;
    z-index: 1;
}

.contact-left{
    width: 40%;
    margin-right: 100px;
}

.contact-under-title-section{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contact-title{
    font-size: 30px;
    margin-top: 0px;
    margin-bottom: 40px;
    font-weight: 400;
    margin-left: -50px;
    color: #d2fa94;
}

.contact-under-title{
    font-size: 20px;
    font-weight: 400;
}

.contact-puce{
    margin-left: -50px;
    margin-right: 50px;
    margin-top: -30px;
}

.contact-form{
    width: 600px;
    background-color: white;
    color: #010064;
    padding: 10px 50px;
}

.contact-form-placement{
    display: flex;
    flex-direction: column;
}

.contact-form-input{
    background-color: white;
    padding: 10px 5px;
    border: none;
    border-bottom: #010064 1px solid;
    width: 100%;
    color: #010064;
    font-size: 18px;
    font-family: DMSans-Regular;
    margin-top: 25px;
}

.contact-form-input::placeholder {
    color: #010064;
    font-size: 18px;
    font-family: DMSans-Regular;
  }

.contact-form-subject{
    padding-left: 5px;
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 18px;
}

.contact-form-checkbox{
    margin-bottom: 30px;
    font-weight: bold;
}

.contact-form-textarea{
    background-color: #d2fa94;
    border: none;
    width: 70%;
    height: 200px;
    margin-top: 15px;
    color: #010064;
    font-size: 18px;
    font-family: DMSans-Regular;
    padding: 15px;
}

.contact-form-textarea::placeholder{
    color: #010064;
    font-size: 18px;
    font-family: DMSans-Regular;
    padding: 15px;
}

.contact-form-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

.contact-form-footer-txt{
    font-size: 22px;
    width: 55%;
}

.contact-form-submit{
    background-color: #d2fa94;
    color: #010064;
    font-size: 18px;
    padding: 12px 30px;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    font-weight: bold;
}

.contact-form-mention{
    font-size: 11px;
}

.contact-mail{
    color: #d2fa94;
    text-decoration: none;
    font-weight: bold;
}

.contact-form-input-subject{
    display: none;
}

@media (max-width: 1400px) {
    .contact-form{
        width: 500px;
        padding: 10px 50px;
    }
}

@media (max-width: 1200px) {
    .contact-form{
        width: 400px;
        padding: 10px 50px;
    }

    .contact-form-textarea{
        width: 90%;
    }

    .contact-title{
        font-size: 25px;
    }
    
    .contact-under-title{
        font-size: 16px;
    }

    .contact-text{
        font-size: 13px;
    }

    .contact-form-input{
        font-size: 16px;
    }
    
    .contact-form-input::placeholder {
        font-size: 16px;
      }

      .contact-form-subject{
        font-size: 16px;
    }
    
    .contact-form-checkbox{
        font-size: 14px;
    }
    
    .contact-form-textarea{
        width: 90%;
        font-size: 16px;
    }

    .contact-form-textarea::placeholder{
        font-size: 16px;
    }

    .contact-form-footer-txt{
        font-size: 18px;
    }

    .contact-form-submit{
        font-size: 16px;
    }

    .contact-form{
        width: 400px;
        padding: 10px 50px;
        margin-right: -70px;
    }

    .contact-left{
        width: 60%;
        margin-right: 60px;
    }
}

@media (max-width: 950px) {
    .contact-form{
        width: 400px;
        padding: 10px 50px;
    }

    .contact-form-textarea{
        width: 90%;
    }

    .contact-title{
        font-size: 25px;
    }
    
    .contact-under-title{
        font-size: 16px;
    }

    .contact-text{
        font-size: 13px;
    }

    .contact-form-input{
        font-size: 16px;
    }
    
    .contact-form-input::placeholder {
        font-size: 16px;
      }

      .contact-form-subject{
        font-size: 16px;
    }
    
    .contact-form-checkbox{
        font-size: 14px;
    }
    
    .contact-form-textarea{
        width: 90%;
        font-size: 16px;
    }

    .contact-form-textarea::placeholder{
        font-size: 16px;
    }

    .contact-form-footer-txt{
        font-size: 18px;
    }

    .contact-form-submit{
        font-size: 16px;
    }

    .contact-form{
        width: 400px;
        padding: 10px 20px;
        margin-right: -90px;
    }

    .contact-left{
        width: 100%;
    }
}

@media (max-width: 768px) {
    .contact-container{
        flex-direction: column;
        align-items: center;
        padding: 50px 100px;
        box-sizing: border-box;
    }

    .contact-form{
        width: 400px;
        padding: 10px 50px;
        margin-left: -100px;
    }
}

@media (max-width: 550px) {
    .contact-container{
        flex-direction: column;
        align-items: center;
        padding: 50px 100px;
        box-sizing: border-box;
    }

    .contact-form{
        width: 400px;
        padding: 5px 15px;
        margin-left: -87px;
    }

    .contact-form-texte{
        font-size: 13px;
    }

    .contact-form-textarea{
        width: 90%;
    }
    
    .contact-form-input{
        font-size: 14px;
    }
    
    .contact-form-input::placeholder {
        font-size: 14px;
      }

      .contact-form-subject{
        font-size: 14px;
    }
    
    .contact-form-checkbox{
        font-size: 13px;
    }
    
    .contact-form-textarea{
        width: 90%;
        font-size: 16px;
    }

    .contact-form-textarea::placeholder{
        font-size: 16px;
    }

    .contact-form-footer-txt{
        font-size: 16px;
    }

    .contact-form-submit{
        font-size: 14px;
    }
}

@media (max-width: 450px) {
    .contact-container{
        flex-direction: column;
        align-items: center;
        padding: 50px 100px;
        box-sizing: border-box;
    }

    .contact-form{
        width: 300px;
        padding: 5px 15px;
        margin-left: -87px;
    }

    .contact-form-texte{
        font-size: 13px;
    }

    .contact-form-textarea{
        width: 90%;
    }
    
    .contact-form-input{
        font-size: 14px;
        width: 100%;
    }
    
    .contact-form-input::placeholder {
        font-size: 14px;
      }

      .contact-form-subject{
        font-size: 14px;
    }
    
    .contact-form-checkbox{
        font-size: 13px;
    }
    
    .contact-form-textarea{
        width: 90%;
        font-size: 16px;
    }

    .contact-form-textarea::placeholder{
        font-size: 16px;
    }

    .contact-form-footer-txt{
        font-size: 16px;
    }

    .contact-form-submit{
        font-size: 14px;
    }

    .contact-left{
        width: 140%;
        margin-right: -10px;
    }
}