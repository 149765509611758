.remerciement {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin-top: 150px;
  margin-bottom: -150px;
}

.remerciement h1 {
  font-size: 56px;
  font-weight: bold;
  margin-bottom: 1rem;
  line-height: 64px;
  text-align: center;
}

.remerciement p {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 40px;
  text-align: center;
}

.remerciement button {
  color: #010064;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  text-decoration: none;
  border-radius: 100px;
  background-color: #d2fa94;
  padding: 20px 30px;
  font-size: 25px;
}

@media (max-width: 1000px) {

  .remerciement {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 10;
    margin-top: 100px;
    margin-bottom: -150px;
  }

  .remerciement h1 {
    font-size: 46px;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 64px;
    text-align: center;
  }
  
  .remerciement p {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .remerciement button {
    color: #010064;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    text-decoration: none;
    border-radius: 100px;
    background-color: #d2fa94;
    padding: 20px 30px;
    font-size: 20px;
  }
}

@media (max-width: 768px) {

  .remerciement h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 64px;
    text-align: center;
  }
  
  .remerciement p {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 40px;
    text-align: center;
    padding: 0 20px;
  }
  
  .remerciement button {
    color: #010064;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    text-decoration: none;
    border-radius: 100px;
    background-color: #d2fa94;
    padding: 20px 30px;
    font-size: 16px;
  }
}

@media (max-width: 500px) {

  .remerciement h1 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 64px;
    text-align: center;
  }
  
  .remerciement p {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 40px;
    text-align: center;
    padding: 0 20px;
  }
  
  .remerciement button {
    color: #010064;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    text-decoration: none;
    border-radius: 100px;
    background-color: #d2fa94;
    padding: 20px 30px;
    font-size: 16px;
  }
}

@media (max-width: 400px) {

  .remerciement {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 10;
    margin-top: 50px;
    margin-bottom: -150px;
  }

  .remerciement h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 64px;
    text-align: center;
  }
  
  .remerciement p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 40px;
    text-align: center;
    padding: 0 20px;
  }
  
  .remerciement button {
    color: #010064;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    text-decoration: none;
    border-radius: 100px;
    background-color: #d2fa94;
    padding: 15px 20px;
    font-size: 16px;
  }
}