.cvc-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.cvc-popup-data {
  display: flex;
  position: relative;
  flex-direction: column;
  color: white;
  max-width: 95%;
}

.cvc-croix {
  position: absolute;
  right: 0;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  width: 35px;
}

.cvc-popup-img {
  width: 410px;
  border-radius: 10px;
}







.cvc-popup-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
  border-radius: 20px;
  width: 650px;
  height: 430px;
  margin: auto;
  overflow-y: auto;
  overflow-x: auto;
}

.cvc-popup-data-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 5px;
  background-color: #010064;
  width: 96%;
  height: 94%;
  border-radius: 16px;
}

.cvc-popup-data p {
  margin: 0;
}

.cvc-popup-data-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 18px;
  padding: 20px 20px 10px 20px;
}

.cvc-popup-data-header p {
  font-size: 12px;
  z-index: 11;
}

.cvc-popup-data-separator {
  background-color: #4d4d93;
  width: 100%;
  height: 50px;
}

.cvc-popup-data-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.cvc-popup-data-content-left {
  width: 65%;
}

.cvc-popup-data-content-left p:nth-child(1) {
  color: #D2FA94;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 35px;
}

.cvc-popup-data-content-left p:nth-child(2) {
  font-size: 24px;
}

.cvc-popup-data-content-left a {
  color: #D2FA94;
}

.cvc-popup-data-content-left a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.cvc-popup-data-content-right p {
  color: #D2FA94;
  font-size: 20px;
}

.cvc-popup-data-content-right img {
  width: 100px;
}

.cvc-popup-data-footer {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: end;
  padding-right: 20px;
  margin-top: -20px;
}

.cvc-popup-data-footer img {
  width: 200px;
}




@media (max-width: 768px) {

  .cvc-popup-img {
    min-width: 250px;
    max-width: 100%;
    border-radius: 10px;
  }

  .cvc-popup-container {
    width: 350px;
    height: 260px;
  }

  .cvc-popup-data-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 12px;
    padding: 20px 20px 5px 20px;
  }

  .cvc-popup-data-header p {
    font-size: 7px;
  }

  .cvc-popup-data-separator {
    height: 25px;
  }

  .cvc-popup-data-content-left {
    width: 70%;
  }

  .cvc-popup-data-content-left p:nth-child(1) {
    font-size: smaller;
  }

  .cvc-popup-data-content-left p:nth-child(2) {
    font-size: smaller;
  }

  .cvc-popup-data-content-right p {
    font-size: smaller;
  }

  .cvc-popup-data-content-right img {
    width: 50px;
  }

  .cvc-popup-data-footer {
    margin-top: -40px;
  }

  .cvc-popup-data-footer img {
    width: 100px;
  }
}