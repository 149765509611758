.prochainement-container{
    width: 100%;
    background-color: #010064;
    padding-bottom: 50px;
    margin-bottom: -200px;
    z-index: 1;
}

.prochainement{
    margin-left: 20%;
}

.prochainement-top{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: end;
}

.prochainement-picto{
    width: 350px;
}

.prochainement-titre{
    font-size: 55px;
    color: white;
    margin-left: -100px;
}

.prochainement-bouton{
    background-color: #d2fa94;
    color: #010064;
    font-size: 20px;
    border: none;
    border-radius: 100px;
    padding: 15px 30px;
    margin-left: 250px;
}

@media (max-width: 1100px) {
    .prochainement-picto{
        width: 250px;
    }

    .prochainement-titre{
        font-size: 45px;
        color: white;
        margin-left: -50px;
    }

    .prochainement-bouton{
        margin-left: 200px;
    }
}

@media (max-width: 768px) {
    .prochainement-picto{
        width: 200px;
    }

    .prochainement-titre{
        font-size: 30px;
        color: white;
        margin-left: -50px;
    }
    
    .prochainement-bouton{
        margin-left: 150px;
    }

    .prochainement-container{
        padding-bottom: 50px;
        margin-bottom: -450px;
    }
   
    .prochainement{
        margin-left: 10%;
    }
}

@media (max-width: 500px) {
    .prochainement-picto{
        width: 100px;
        margin-bottom: 50px;
    }

    .prochainement-titre{
        font-size: 25px;
        color: white;
        margin-left: -50px;
    }
    
    .prochainement-bouton{
        margin-left: 50px;
        font-size: 16px;
        width: 200px;
        padding: 15px 0px;
    }

    .prochainement-container{
        padding-bottom: 50px;
        margin-bottom: -450px;
    }
   
    .prochainement{
        margin-left: 10%;
    }
}