.mention-legal-container {
  overflow-y: auto;
}

.mention-legal-container h1 {
  font-size: 24px;
}

.mention-legal-container li {
  font-size: 18px;
  font-weight: bold;
}

.mention-legal-container p {
  font-size: 14px;
}