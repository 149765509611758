.ressources-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.ressources-intro {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 60px 40px 40px 100px;
  background-color: #e4e3d8;
}

.ressources-intro-left {
  grid-column: 1 / 7;
}

.ressources-intro-right {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 7 / 13;
}

.ressources-intro-right img {
  max-width: 400px;
}

.ressources-intro-title {
  font-size: 60px;
  margin: 0;
  padding-bottom: 30px;
}

.ressources-intro-description {
  font-size: 20px;
}

.ressources-guide {
  background-color: #010064;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 60px;
  color: white;
}

#ressources {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #010064;
  margin: 50px 15px 0 0px;
}


.ressources-content-data {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  text-align: center;
}

.ressources-content-data a {
  text-decoration: none;
  color: white;
}

.ressource-content-data-img {
  width: 120px;
  height: 100px;
  object-fit: contain;
}

.ressource-content-data-title {
  color: #d2fa94;
}

.ressource-content-data-title:hover {
  color: #d2fa94;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}

.ressources-status{
  background-color: #d2fa94;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  color: #010064;
}








.txt-bleu{
  color: #010064;
}

#sous-titre-status{
  font-size: 20px;
  margin-top: 20px;
}

#lienstatus{
  text-decoration: underline;
  color: #010064;
}

#icone-serveur{
  min-width: 100px;
  width: 300px;
  margin-left: 5%;
  margin-right: 5%;
}


@media (max-width: 768px) {
  .ressources-intro {
    display: flex;
    padding: 20px 10px 10px 15px;
    background-color: #e4e3d8;
  }

  .ressources-intro-right img {
    display: none;
  }

  .ressources-intro-title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 0;
  }

  .ressources-intro-description {
    font-size: 16px;
    margin: 10px 0 30px 0;
  }

  .ressources-intro-right {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 7 / 13;
  }

  #ressources {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-template-rows: auto;
    column-gap: 20px;
    row-gap: 20px;
    background-color: #010064;
    margin: 50px 60px 0 60px;
  }

  .ressources-content-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    text-align: center;
  }

  .ressources-content-data a {
    text-decoration: none;
    color: white;
  }

  .ressource-content-data-img {
    width: 120px;
    height: 100px;
  }

  .ressource-content-data-title {
    color: #d2fa94;
  }

  .ressource-content-data-title:hover {
    color: #d2fa94;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
  }


  #sous-titre-status{
    font-size: 14px;
    margin-left: 50px;
    margin-top: 20px;
  }

  #icone-serveur {
    min-width: 80px;
    width: 100px;
    margin-left: 5%;
    margin-right: -5%;
  }


}