.about-us-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: #e4e3d8;
  overflow: hidden;
  margin-bottom: -75px;
  z-index: 1;
}

.about-us-content-left {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 15px 10px 20px 50px;
}

.about-us-content-left h1 {
  color: #d2fa94;
  font-size: 34px;
  font-weight: normal;
  line-height: 38px;
  word-break: break-word;
}

.about-us-content-left span {
  color: #d2fa94;
}

.about-us-content-left p {
  font-size: 20px;
  line-height: 23px;
  word-break: break-word;
}

.about-us-content-left ul {
  margin-right: 20px;
}

.about-us-content-left li {
  font-size: 20px;
  line-height: 23px;
  word-break: break-word;
}

.about-us-content-left ul p {
  margin: 0 0 20px 0;
  font-size: 20px;
  line-height: 23px;
  word-break: break-word;
}


.about-us-content-right {
  margin-top: 20px;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  right: 0;
  width: 40%;
  height: 100%;
  padding: 0 30px;
}

.about-us-content-right h1 {
  color: #d2fa94;
  font-size: 25px;
  font-weight: normal;
  margin-bottom: 10px;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 60%;
  margin-bottom: 50px;
}

.about-us-content-right img {
  border-radius: 50%;
}

.about-us-separator {
  width: 100%;
  height: 2px;
  background-color: white;
  margin: 0;
}

.about-us-content-right div p {
  margin: 0;
}

.about-frise {
  width: 80%;
  max-width: 600px;
}

.profile-info {
  margin: 10px 0 10px 0;
}

.mobile-about-us-content-left {
  display: none;
}


@media (max-width: 768px) {

  .about-us-content-left {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 15px 10px 20px 20px;
  }

  .about-us-content-left h1 {
    font-size: 24px;
    line-height: 28px;
  }

  .about-us-content-left p {
    font-size: 16px;
    line-height: 23px;
    word-break: break-word;
  }

  .about-us-content-left ul {
    margin: 0;
    padding: 0 20px 0 20px;
  }

  .about-us-content-left li {
    font-size: 14px;
    line-height: 23px;
    word-break: break-word;
  }

  .about-us-content-left ul p {
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 23px;
    word-break: break-word;
  }

  .about-us-content-left img {
    display: none;
  }

  .company-awards {
    display: none;
  }


  .about-us-content-right {

    display: flex;
    position: initial;
    flex-direction: column;
    align-items: center;
    right: 0;
    width: auto;
    padding: 0 30px;
    margin-top: 10px;
  }

  .about-us-content-right h1 {
    white-space: nowrap;
    color: #d2fa94;
    font-size: 25px;
    font-weight: normal;
    margin-bottom: 10px;
  }

  .profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-bottom: 50px;
  }

  .about-us-content-right img {
    width: 125px;
  }

  .about-us-separator {
    width: 90%;
    height: 2px;
    background-color: white;
    margin: 0;
  }

  .about-us-content-right div p {
    font-size: 12px;
    margin: 0;
  }

  .profile-info {
    margin: 10px 0 10px 0;
  }

  .mobile-about-us-content-left {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 15px 10px 20px 20px;
  }

  .mobile-about-us-content-left h1 {
    color: #d2fa94;
    font-size: 24px;
    font-weight: normal;
    line-height: 28px;
    word-break: break-word;
  }

  .mobile-about-us-content-left span {
    color: #d2fa94;
  }

  .mobile-about-us-content-left p {
    font-size: 16px;
    line-height: 23px;
    word-break: break-word;
  }

  .mobile-about-us-content-left ul {
    margin: 0;
    padding: 0 20px 0 20px;
  }

  .mobile-about-us-content-left li {
    font-size: 14px;
    line-height: 23px;
    word-break: break-word;
  }

  .mobile-about-us-content-left ul p {
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 23px;
    word-break: break-word;
  }

  .mobile-about-us-content-left img {
    align-self: center;
  }

}