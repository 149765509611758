.rnsv-container{
  width: 100%;
}

.rnsv-iframe{
  margin-top: -100px;
  width: 100%;
  height: 1200px;
  border: none;
}

.toast{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.toast-bouton{
  font-size: 16px;
  border-radius: 5px;
  padding: 15px 20px;
  margin-top: 10px;
}

.toast-text{
  font-size: 18px;
  margin-top: 10px;
  text-align: justify;
}

.toast-mail{
  color: #010064;
}