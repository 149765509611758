.accueil-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: white;
}

.accueil-intro {
  display: flex;
  flex-direction: column;
  background-color: #010064;
}

.accueil-intro-content {
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: center;
  justify-content: center;
  margin: 50px 200px 0 200px;
}

.accueil-intro-content p {
  margin: 0;
}

.accueil-intro-content div {
  display: flex;
  align-items: center;
}

.accueil-intro-content-title {
  word-break: break-word;
  font-size: 60px;
  line-height: 70px;
  text-align: center;
  color: #d2fa94;
  margin: 0;
  font-weight: bolder;
}

.content-intro-content-p1 {
  color: white;
  text-align: center;
  margin-top: 20px;
  font-size: 25px;
}

.content-intro-content-p2 {
  flex-direction: row;
  color: #d2fa94;
  text-align: center;
  margin-top: 20px;
  font-size: 25px;
}

.content-intro-content-p2 img {
  width: 61px;
  padding-right: 10px;
}

.content-intro-content-p3 {
  flex-direction: column;
  color: white;
  text-align: center;
  margin-top: 40px;
  font-size: 20px;
}

.content-intro-content-p4 {
  flex-direction: column;
  color: #9999c0;
  margin-top: 60px;
  font-size: 20px;
  text-align: center;
}

.content-intro-content-p4 img {
  margin-left: -110px;
  margin-bottom: -10px;
  margin-top: -5px;
}

.content-intro-content-p5 {
  flex-direction: column;
  color: #9999c0;
  margin-top: 30px;
  font-size: 16px;
  text-align: center;
}

.accueil-intro-img {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -340px;
  padding: 0 30px;
  z-index: 0;
}

.accueil-intro-img-left {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: end;

}

.accueil-intro-img-right {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
}


.section-header {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 20px;
}

.section-header-title {
  white-space: nowrap;
  display: flex;
  height: 100%;
  flex-direction: row;
  grid-column: 1/3;
  margin-left: 60%;
  margin-top: 50px;
}

.section-header-logo {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.section-header-name {
  font-size: 19px;
  font-weight: normal;
  margin: 0;
}

.section-header-description {
  display: flex;
  flex-direction: column;
  grid-column: 3/13;
  margin: 40px 0 0 20%;
  font-size: 35px;
  font-weight: normal;
}

.section-header-description p {
  margin: 0;
  width: auto;
}

.section-header-description strong {
  color: #010064;
  font-weight: bolder;
}


.accueil-services {
  background-color: #e4e3d8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 60px;
  color: black;
}

.accueil-services-content {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-template-rows: auto;
  column-gap: 20px;
  row-gap: 20px;
  margin: 30px 80px 0 80px;
  color: black;
}

.accueil-services-content-data {
  display: flex;
  grid-column: auto;
  min-width: 100px;
  flex-direction: column;
  margin: 20px 0 0 20%;
  overflow: hidden;
}

.accueil-services-content-data a {
  display: flex;
  width: 100%;
  flex-direction: column;
  text-decoration: none;
  color: #010064;
  font-size: 25px;
}

.service-picto {
  display: flex;
  width: 100%;
  position: relative;
  min-width: 10px;
  max-width: 200px;
  aspect-ratio: 1 / 1;
}

.accueil-services-content-data img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  border-radius: 10%;
}

.accueil-services-content-data h4 {
  margin: 20px 0 0 0;
}

.accueil-services-content-data h4:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
}

.accueil-services-content-data p {
  margin: 10px 0 0 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
}



.accueil-technologies {
  background-color: #d2fa94;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 60px;
  color: black;
}

.accueil-technologies-content {
  display: grid;
  flex: 1;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  margin-top: 30px;
  color: black;
}

.accueil-technologies-content-image {
  display: flex;
  flex-direction: column;
  grid-column: 1 / 4;
  margin: 20px 0 0 20%;
}

.accueil-technologies-content-image img {
  max-width: 302px;
  min-width: 100px;
  margin: -50px -40px -30px 15%;
}

.accueil-technologies-content-data {
  display: grid;
  grid-column: 5 / 13;
  grid-template-columns: repeat(2, 1fr);
  max-width: 75vw;
  grid-template-rows: auto;
  column-gap: 40px;
  row-gap: 20px;
  margin: 20px 10% 0 0;
}

.accueil-technologies-content-data div {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
}

.accueil-technologies-content-data img {
  max-width: 105px;
}

.accueil-technologies-content-data p {
  font-size: 24px;
  font-weight: bolder;
  margin: 0;
}

.accueil-inscription {
  background-color: #010064;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 60px;
  color: white;
}

.accueil-inscription-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: -20px calc(-50px + 25%) 0 calc(-50px + 15%);
}

.accueil-inscription-content-left {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 20%;
}

.accueil-inscription-content-left p {
  font-size: 35px;
  margin: 0;
}

.accueil-inscription-content-left div {
  margin: 40px 0 40px;
}

.accueil-inscription-content-left button {
  text-decoration: none;
  color: #010064;
  background-color: #d2fa94;
  border-radius: 100px;
  font-size: 20px;
  padding: 13px 22px;
  border: none;
  cursor: pointer;
}

.accueil-inscription-content-right {
  margin-top: -40px;
}



.accueil-partenaires {
  background-color: #d2fa94;
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
  margin-bottom: -10px;
  color: black;
}

.partenaires-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.partenaires-content img {
  width: 10%;
  max-width: 150px;
}

.accueil-partenaires p {
  background-color: #d2fa94;
  display: flex;
  flex-direction: column;
  margin: 20px 0 20px 0 ;
  align-items: center;
  font-size: 18px;
}

#lien-entrust{
  color: #9999b1;
  overflow-wrap: anywhere;
}

.mobile-section-header {
  display: none;
}

@media (max-width: 1450px) {
  .accueil-intro-img-gauche{
    width: 35%;
    margin-top: 100px;
  }

  .accueil-intro-img-droite{
    width: 40%;
  }

  .content-intro-content-p3{
    margin-top: 25px;
  }

  .content-intro-content-p4{
    margin-top: 30px;
  }
}

@media (max-width: 1180px) {
  .accueil-intro-img-gauche{
    margin-top: 150px;
  }

  .content-intro-content-p1{
    font-size: 24px;
  }

  .content-intro-content-p2{
    font-size: 24px;
  }

  .content-intro-content-p2 img{
    width: 60px;
  }

  .content-intro-content-p3{
    font-size: 18px;
  }

  .content-intro-content-p4{
    font-size: 18px;
  }

  .content-intro-content-p4 img{
    margin-left: -100px;
  }

  .content-intro-content-p5{
    font-size: 14px;
  }
}

@media (max-width: 1020px) {
  .accueil-intro-img-gauche{
    width: 40%;
    margin-top: 150px;
  }

  .accueil-intro-img-droite{
    width: 40%;
  }
  .accueil-intro-content-title{
    font-size: 40px;
  }

  .content-intro-content-p1{
    font-size: 20px;
  }

  .content-intro-content-p2{
    font-size: 20px;
  }

  .content-intro-content-p2 img{
    width: 50px;
  }

  .content-intro-content-p3{
    font-size: 16px;
  }

  .content-intro-content-p4{
    font-size: 16px;
  }

  .content-intro-content-p4 img{
    margin-left: -90px;
    width: 100px;
  }

  .content-intro-content-p5{
    font-size: 12px;
  }
}

@media (max-width: 768px) {

  .cvc-popup-container {
    width: 350px;
    height: 260px;
  }


  .accueil-intro-content-title {
    font-size: 28px;
    line-height: 40px;
  }

  .accueil-intro {
    flex-wrap: wrap;
  }
  
  .accueil-intro-content {
    margin: 50px 10px 50px 10px;
    flex-wrap: wrap;
  }

  .content-intro-content-p1 {
    font-size: 18px;
  }

  .content-intro-content-p2 {
    font-size: 18px;
  }

  .content-intro-content-p2 img {
    width: 40px;
  }

  .content-intro-content-p3 {
    font-size: 16px;
  }

  .content-intro-content-p4 {
    font-size: 14px;
  }

  .content-intro-content-p4 img {
    margin-left: -80px;
  }

  .content-intro-content-p5 {
    font-size: 14px;
  }

  .accueil-intro-img {
    display: none;
  }

  .section-header {
    display: none;
  }

  .mobile-section-header {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-top: 20px;
  }

  .section-header-title {
    grid-column: 1/3;
    margin-left: 50px;
    margin-top: 0;
  }

  .section-header-logo {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  .section-header-name {
    font-size: 19px;
    font-weight: normal;
    margin: 0;
  }

  .section-header-description {
    display: flex;
    flex-direction: column;
    grid-column: 3/13;
    margin: 20px 50px 0 50px;
    font-size: 20px;
    font-weight: normal;
  }

  .section-header-description p {
    margin: 0;
    width: auto;
  }

  .accueil-services-content-data {
    text-align: center;
  }

  .accueil-services-content-data img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10%;
  }

  .accueil-services-content-data a{
    font-size: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .accueil-services-content-data p{
    font-size: 16px;
  }

  .service-picto {
    min-width: 120px;
    max-width: 125px;
  }

  .section-header-description strong {
    color: #010064;
    font-weight: bolder;
  }

  .accueil-services-content {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    margin: 15px 20px 0 20px;
  }

  .accueil-services-content-data {
    margin: 20px 0 0 0;
  }

  .accueil-technologies-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
  }

  .accueil-technologies-content-image {
    display: none;
  }

  .accueil-technologies-content-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: initial;
    margin: 20px 50px 0 50px;
  }

  .accueil-technologies-content-data div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0 0 0;
  }

  .accueil-technologies-content-data img {
    max-width: 85px;
    margin-right: 30px;
  }

  .accueil-technologies-content-data p {
    font-size: 14px;
    font-weight: bolder;
    margin: 0;
  }

  .accueil-inscription-content-left {
    display: flex;
    flex-direction: column;
    margin: 20px 0 0 0;
  }

  .accueil-inscription-content-left p {
    font-size: 20px;
    line-height: 31px;
    margin: 0;
  }

  .accueil-inscription-content-left div {
    margin: 40px 0 0;
  }

  .accueil-inscription-content-left button {
    text-decoration: none;
    color: #010064;
    background-color: #d2fa94;
    border-radius: 100px;
    font-size: 20px;
    padding: 13px 22px;
    border: none;
    cursor: pointer;
  }

  .accueil-inscription-content-right {
    margin-top: -40px;
  }

  .accueil-inscription-content-right img {
    display: none;
  }

  .accueil-partenaires p{
    margin: 10px 0;
    font-size: 11px;
  }
}