.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
}

.footer-img-separator {
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #010064;
  padding-top: 60px;
  max-width: 100%;
  width: 100%;
  margin: auto;
}

.footer-grid {
  display: grid;
  flex: 1;
  justify-content: space-between;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 20px;
  padding: 20px 40px 30px;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  grid-column: 1 / 9;
  align-self: flex-start;
}

.footer-contact-text-1 {
  color: #d2fa94;
  font-size: 38px;
  line-height: 40px;
  text-align: left;
}

.footer-contact-text-2 {
  color: white;
  font-size: 36px;
  text-align: left;
  line-height: 36px;
}

.footer-contact-text-3 {
  color: white;
  font-size: 20px;
  text-align: left;
  line-height: 23px;
  margin-top: 5px;
}

.footer-contact-text-4 {
  color: white;
  font-size: 20px;
  text-align: left;
  line-height: 23px;
  margin-top: 5px;
}

.footer-contact-text-4 a {
  color: white;
}

.footer-contact-button {
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: #010064;
  padding: 15px 20px;
  margin: 20px 0 0;
  font-size: 20px;
  align-self: flex-start;
  background: #d2fa94;
  line-height: 23px;
  border-radius: 100px;
}

.footer-contact-social {
  display: flex;
  justify-content: space-between;
  width: 250px;
  position: relative;
  margin-top: 140px;
  padding: 10px;
}

.footer-contact-social img {
  display: block;
  position: relative;
  margin: 0;
  width: 50px;
}


.footer-navbar {
  display: flex;
  flex-direction: column;
  grid-column: 9 / 13;
  align-self: flex-start;
}

.footer-navbar-link {
  display: flex;
  flex-direction: column;
}

.footer-navbar-link a {
  display: flex;
  margin-bottom: 10px;
  font-size: 20px;
  text-align: right;
  line-height: 23px;
  align-self: end;
  text-decoration: none;
  color: white;
}

.footer-logo {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.footer-logo img {
  display: block;
  object-fit: contain;
  width: 250px;
}


.footer-legal {
  margin-top: 137px;
  flex: 1;
  font-size: 10px;
  display: flex;
  justify-content: end;
}

.footer-legal div {
  padding-left: 50px;
}

.footer-legal a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.footer-info {
  padding: 10px;
  align-self: center;
  font-size: 13px;
}

.popup-footer {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-top: 20px;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.croix-footer {
  position: absolute;
  right: 0;
  padding: 10px;
  cursor: pointer;
  z-index: 100;
  width: 35px;
}

.popup-footer-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 600px;
  margin: auto;
  overflow-y: auto;
  overflow-x: auto;
}

.popup-footer-data {
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: black;
}


.mobile-footer-logo {
  display: none;
}

[x-autocompletetype="off"],
[autocomplete="off"] {
  text-decoration: none;
  color: white;
}

@media (max-width: 768px) {
  .footer-img-separator {
    width: 100%;
  }

  .footer-grid {
    padding: 5px 15px;
  }

  .footer-logo {
    display: none;
  }

  .mobile-footer-logo {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .mobile-footer-logo img {
    display: block;
    object-fit: contain;
    width: 160px;
  }

  .footer-contact-text-1 {
    font-size: 20px;
    line-height: normal;
    text-align: left;
  }

  .footer-contact-text-2 {
    font-size: 20px;
    text-align: left;
    line-height: normal;
  }

  .footer-contact-text-3 {
    font-size: 14px;
    text-align: left;
    line-height: normal;
  }

  .footer-contact-text-4 {
    font-size: 14px;
    text-align: left;
    line-height: normal;
  }

  .footer-contact-button {
    padding: 5px 24px;
    margin: 20px 0 0;
    font-size: 13px;
    line-height: 30px;
    border-radius: 100px;
  }

  .footer-contact-social {
    display: flex;
    justify-content: flex-start;
    width: 200px;
    position: relative;
    margin-top: 5px;
    padding: 10px;
  }

  .footer-contact-social img {
    display: block;
    position: relative;
    margin-right: 5px;
    width: 40px;
  }

  .footer-navbar {
    margin-top: 50px;
  }

  .footer-navbar-link a {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
  }

  .footer-legal {
    margin-top: 100px;
    font-size: 10px;
    flex-wrap: nowrap;
    display: flex;
    justify-content: end;
  }

  .footer-legal div {
    padding-left: 50px;
  }

  .footer-legal a {
    white-space: nowrap;
  }

  .footer-info {
    padding: 20px 10px 10px 10px;
    font-size: 7px;
  }




}